<template>
  <div class="StatusPageActions">
    <div ref="dots"
         class="dots-button">
      <icon-dots-horizontal width="16"
                            height="16"
                            color="rgb(178, 182, 195)" />
    </div>

    <div ref="dropdown">
      <nav class="nav">
        <router-link v-if="!statusPage.is_over_limit" class="nav-item" :to="editRoute">Settings</router-link>
        <router-link v-if="!statusPage.is_over_limit" class="nav-item" :to="monitorsRoute">Monitors</router-link>
        <router-link v-if="!statusPage.is_over_limit" class="nav-item" :to="incidentsRoute">Incidents</router-link>
        <router-link v-if="!statusPage.is_over_limit" class="nav-item" :to="maintenanceRoute">Maintenance</router-link>
        <router-link v-if="!statusPage.is_over_limit" class="nav-item" :to="customizationRoute">Customization</router-link>
        <router-link v-if="!statusPage.is_over_limit" class="nav-item" :to="languageRoute">Language</router-link>

        <a href="#"
           class="nav-item"
           @click.prevent="onDelete">Delete</a>

        <a href="#"
           class="nav-item nav-item-copy"
           @click.prevent="$emit('copy-link')">Copy link</a>
      </nav>
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js'
import statusPagesApi from '@/api/statusPagesApi.js'
import swal from 'sweetalert2'

export default {
  props: {
    statusPage: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      tippyInstance: null
    }
  },

  mounted () {
    this.initDropdown()
  },

  methods: {
    initDropdown () {
      this.tippyInstance = tippy(this.$refs.dots, {
        content: this.$refs.dropdown,
        interactive: true,
        theme: 'light-border',
        animation: 'shift-away-subtle',
        placement: 'left',
        arrow: true,
        inertia: true,
        trigger: 'click',
        maxWidth: 'none'
      })
    },

    async onDelete () {
      swal.fire({
        html: '<h4>' + 'Are you sure that you want to permanently delete "' + this.statusPage.title + '" status page?</h4>',
        title: '',
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        showCancelButton: true,
        confirmButtonColor: '#eb5757',
        cancelButtonColor: '#9ca3a1',
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel'
      }).then(async (val) => {
        if (val.value === true) {
          try {
            this.tippyInstance.hide()
            await statusPagesApi.delete(this.statusPage.id)
            await this.refreshCurrentUser() // In order to update status pages number
            this.$emit('delete')
          } catch (e) {
            alert('Something went wrong!')
          }
        }
      })
    },

    ...mapActions('authentication', [
      'refreshCurrentUser'
    ])
  },

  computed: {
    editRoute () {
      return {
        name: 'statusPages.update',
        params: {
          id: this.statusPage.id
        }
      }
    },
    monitorsRoute () {
      return {
        name: 'statusPages.single.monitors',
        params: {
          id: this.statusPage.id
        }
      }
    },
    incidentsRoute () {
      return {
        name: 'statusPages.single.incidents',
        params: {
          id: this.statusPage.id
        }
      }
    },
    maintenanceRoute () {
      return {
        name: 'statusPages.single.maintenance',
        params: {
          id: this.statusPage.id
        }
      }
    },
    customizationRoute () {
      return {
        name: 'statusPages.single.customization',
        params: {
          id: this.statusPage.id
        }
      }
    },
    languageRoute () {
      return {
        name: 'statusPages.single.language',
        params: {
          id: this.statusPage.id
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .StatusPageActions {
    .dots-button {
      @include inline-flex-center;

      cursor: pointer;
      transition: 0.2s all;
      padding: 8px;
      border-radius: 4px;

      &:hover {
        background-color: #f6f5f7;

        ::v-deep svg g {
          fill: map-get($colors, purple-1) !important;
        }
      }
    }

    .nav {
      width: 140px;

      .nav-item {
        display: block;
        padding: 8px 10px;
        color: map-get($colors, gray-1);
        font-size: 15px;
        text-decoration: none;
        font-weight: 500;
        border-radius: 4px;

        &-copy {
          display: none;
          @media (max-width: 990px) {
            display: block;
          }
        }

        &:hover {
          background-color: map-get($colors, purple-1);
          color: #fff;
        }
      }
    }
  }
</style>
